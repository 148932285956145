@import "~semantic-ui-css/semantic.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import "./../styling/variables.scss";

#root {
  position: relative;
  min-height: 100vh;
  min-width: 100%;
}

.ui.blue.header {
  color: $buttonColor !important;
}

.container {
  padding-top: 50px;
  padding-bottom: 2.5rem;
}
.contentSection {
  padding-top: 20px !important;
  padding-left: 70px !important;
  margin-left: 0 !important;
  max-width: 1300px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
  background-color: $backgroundColor !important;
  min-height: 500px;
}

body {
  font-family: "Open Sans", sans-serif;
}

body p {
  font-style: normal;
}

body h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 3em;
}

body a {
  font-style: normal;
  font-size: 15px;
  font-weight: normal;
  color: $linkColor;
}

h1.ui.header {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 3em;
}

h2.ui.header {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 2em;
  text-align: left;
  color: $titleColor;
}

h3.ui.header,
h4.ui.header,
h5.ui.header,
h6.ui.header {
  font-family: "Open Sans", sans-serif;
}

h5.ui.header {
  font-size: 1.2em;
}
h6.ui.header {
  font-style: normal;
  font-size: 1.1em;
  font-weight: normal;
  color: $subtitleColor;
  margin: 0px 0px 30px 0px;
}
h4.ui.header {
  color: $titleColor;
  line-height: 21.79px;
  text-align: left;
  font-size: 1.15em;
}

.ui.primary {
  &.button,
  &.buttons .button {
    background-color: $buttonColor;
    color: #fff;
    height: 50px;

    &:hover {
      background-color: $buttonColor;
      box-shadow: 3px 4px 10px 0 $shadowColor !important;
      color: #fff;
    }

    &.rounded {
      padding: 0 40px !important;
      font-size: 1.1em;
      border: none;
      height: 50px;
      background-color: $buttonColor;
      border-radius: 6.3px;
      box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.05);

      &.disabled {
        background-color: $buttonDisabledColor;
        border: 1px solid $buttonDisabledBorderColor;
        opacity: 1 !important;
      }
    }
  }

  &.buttons {
    // If the browser has a rounding calculation issue for the width of the components, this will ensure there is no whitespace between the buttons
    background-color: $buttonColor;
    border-radius: 6.3px;
  }
  &.buttons .button {
    &:hover,
    &:active,
    &.active {
      background-color: #1b8fd8 !important;
    }
    &:not(:hover) {
      // Keep the hover shadow from overlapping on sibling buttons
      z-index: 1;
    }
  }
}

.button:active {
  background-color: #1b8fd8 !important;
}

.floatedFormBox button.ui.grey.large.button:active {
  background-color: grey !important;
}

.ui.simple.button {
  background-color: white;
  color: $titleColor;
  height: 40px;
  padding: 0 20px;
  border-radius: 7px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 24px;
  text-align: center;
}

.centerContainer {
  text-align: center !important;
}

.ui.simple.button:hover {
  background-color: white;
  color: $buttonColor;
  border: 1px solid $buttonColor;
  box-shadow: 3px 4px 10px 0 $shadowColor !important;
}

button.ui.compact.fluid.icon.button.secondary {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  color: $titleColor;
  height: 50px;
  background-color: white;
  font-size: 1.15em;
}

button.ui.button.deleteAction {
  border: 1px solid $alertColor;
  border-radius: 7px;
  color: white;
  height: 50px;
  background-color: $alertColor;
  font-size: 1.15em;
  --antd-wave-shadow-color: $alertColor;

  &:hover {
    border: 1px solid $alertColor;
    border-radius: 7px;
    color: white;
    height: 50px;
    background-color: $alertColor;
    font-size: 1.15em;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }
  &:focus {
    border: 1px solid $alertColor;
    border-radius: 7px;
    color: white;
    height: 50px;
    background-color: $alertColor;
    font-size: 1.15em;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }
  &:active {
    border: 1px solid $blockedTagsColor;
    background-color: $alertColor;
  }
}
button.ui.button.secondary {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  color: $titleColor;
  height: 50px;
  background-color: white;
  font-size: 1.15em;

  &:hover {
    border: 1px solid #eeeeee;
    border-radius: 7px;
    color: $titleColor;
    height: 50px;
    background-color: white;
    font-size: 1.15em;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }
  &:focus {
    border: 1px solid #eeeeee;
    border-radius: 7px;
    color: $titleColor;
    height: 50px;
    background-color: white;
    font-size: 1.15em;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  }
}

.buttonWithLateralPadding {
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin-left: 20px !important;
}

button.ui.compact.fluid.icon.button.secondary.bordered {
  border: 1px solid $buttonColor !important;
}

button.ui.button.secondary.bordered.blue {
  border: 1px solid $buttonColor !important;
  color: $buttonColor;
  font-weight: 400;
  font-size: 16px;
}

.ui.second.button {
  background-color: white;
  color: black;
  height: 45px;
}

.ui.second.button:hover {
  background-color: $buttonColor;
  box-shadow: 3px 4px 10px 0 $shadowColor !important;
}

.ui.second.button.rounded {
  border-radius: 5px;
  padding: 0 40px;
  height: 30px;
}

.ui.second.button.rounded.disabled {
  background-color: white;
  opacity: 1 !important;
}

.creditCardName {
  font-size: 0.875em !important;
}

.ui.form input[type="password"],
.ui.form input[type="email"],
.ui.form input[type="text"],
.ui.form input[type="date"] {
  color: $titleColor;
  background-color: #ffffff;
  border: 1px solid $borderColor;
  border-radius: 7px;
  height: 45px;
  font-size: 1.143em;
}

.ui[class*="left icon"].input > i.icon {
  color: $iconColor;
}

#birthdate:disabled {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.45;
}

.whiteContainer {
  background-color: #fff;
  display: flex;
  overflow: hidden;
}

.sideBarContainer {
  z-index: 2;
  background-image: linear-gradient(270deg, #eeeef0, #f8f8fa);
  min-height: 900px;
  width: 230px;
  padding-top: 120px;
  padding-left: $bigSize;
}

.socialContent {
  max-width: $maxWidthSizeContainer;
}
.socialSectionContent {
  max-width: $maxWidthFormSize;
}
.ui.grid.content {
  display: flex;
  flex-wrap: wrap;
  padding: 60px;
  width: 100%;
}

.ui.vertical.menu.menuBox {
  border: none;
  box-shadow: none;
}

.sideBar {
  margin-left: 45px;
  margin-top: 80px;
}

.ui.menu .item {
  line-height: 1.5;
}

.ui.vertical.menu {
  background: rgba(255, 255, 255, 0.5);
}

.ui.vertical.menu .item.selectedItem {
  border-left: 5px solid black;
  border-right: none;
  border-bottom: none;
  border-top: none;
  font-weight: 700;
  background: white;
  border-radius: 0 !important;
}

.ui.vertical.menu .item.basicItem {
  font-weight: 700;
  color: $labelColor;
  border: none;
  border-radius: 0 !important;
}

.ui.vertical.menu .item.basicItem.disabled {
  background-color: $backgroundColor;
  color: $labelColor;
  opacity: 0.2;
}

.ui.vertical.menu .item:before {
  background-color: transparent;
}

.ui.left.floated.button.button-link,
.ui.right.floated.button.button-link {
  color: $buttonColor;
  cursor: pointer;
  background: transparent;
}

.button-link .underline {
  color: $buttonColor;
  text-decoration: underline;
  cursor: pointer;
}

.noPadding {
  padding: 0 !important;
}

.tinyPaddingLeft {
  padding-left: $xsSize !important;
}

.smallPaddingLeft {
  padding-left: $smallSize !important;
}

.bigPaddingLeft {
  padding-left: $bigSize !important;
}

.xbigPaddingLeft {
  padding-left: $xbigSize !important;
}

.xxbigPaddingLeft {
  padding-left: $xxbigSize !important;
}

.smallPaddingTop {
  padding-top: $smallSize !important;
}

.mediumPaddingTop {
  padding-top: $mediumSize !important;
}

.bigPaddingTop {
  padding-top: $bigSize !important;
}

.xbigPaddingTop {
  padding-top: $xbigSize !important;
}

.paddingTop {
  padding-top: $bigSize !important;
}

.smallPadding {
  padding: $smallSize !important;
}

.mediumPadding {
  padding: 15px !important;
}

.hugePadding {
  padding: $hugeSize !important;
}
.basicPadding {
  padding: 15px 0 !important;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.smallPaddingBottom {
  padding-bottom: $xsSize !important;
}

.mediumPaddingBottom {
  padding-bottom: 15px !important;
}

.marginTop {
  margin-top: $xbigSize !important;
}

.smallMarginTop {
  margin-top: $xsSize !important;
}

.mediumMarginTop {
  margin-top: $smallSize !important;
}

.bigMarginTop {
  margin-top: $mediumSize !important;
}

.noPaddingLateral {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.noPaddingHorizontal {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.noPaddingRight {
  padding-right: 0 !important;
}

.leftPadding {
  padding-left: 15px !important;
}

.bigxLeftPadding {
  padding-left: $xbigSize !important;
}

.smallRightPadding {
  padding-right: $smallSize !important;
}

.smallLeftPadding {
  padding-left: $smallSize !important;
}

.mediumVerticalPadding {
  padding-left: $mediumSize !important;
  padding-right: $mediumSize !important;
}

.noMargin {
  margin: 0 !important;
}

.noMarginTop {
  margin-top: 0 !important;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.simpleMargin {
  margin: 3px !important;
}

.mediumMargin {
  margin: $mediumSize !important;
}

.leftMargin {
  margin-left: 15px !important;
}

.leftMarginSmall {
  margin-left: $smallSize !important;
}

.mediumLeftMargin {
  margin-left: 37px !important;
}

.marginBottom {
  margin-bottom: 20px !important;
}

.smallMarginBottom {
  margin-bottom: $smallSize !important;
}

.mediumMarginRight {
  margin-right: $mediumSize !important;
}

.gridBaseWidth {
  max-width: $maxWidthSizeContainer !important;
  min-width: 984px !important;
}

.negativeMarginTop {
  margin-top: -5px !important;
}

.negativeLeftMargin {
  margin-left: -4px !important;
}

.negativeRightMargin {
  margin-right: -100px !important;

  @media (max-width: 1200px) {
    margin-right: -40px !important;
  }
}

.rightPadding {
  padding-right: 35px !important;
}

.bigRightPadding {
  padding-right: $bigSize !important;
}

.inputWithSlugLabel {
  .ui.label.label {
    margin-top: 6px;
    line-height: 23px;
    background-color: #eeeeee;
  }

  button.ui.primary.icon.button {
    color: $buttonColor;
    background-color: white;
    border-top: 0.9px solid $boxLightBorder;
    border-bottom: 0.9px solid $boxLightBorder;
    border-right: 0.9px solid $boxLightBorder;
    height: 45px !important;
  }
}

.inputWithLabel {
  .ui.label.label {
    line-height: 23px;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $iconColor !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $iconColor !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $iconColor !important;
}

span.blueLink {
  color: $buttonColor !important;
}

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  background-color: $buttonColor !important;
  transform: scale(1);
}
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: $buttonColor !important;
  transform: scale(0.6);
  height: 15px !important;
  width: 15px !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  background-color: $buttonColor;
  color: white !important;
  border-radius: 5px;
  font-size: 12px;
}

.greyBox {
  background-color: $backgroundColor;
  border-radius: 6px;
  min-height: 86px;
  padding: 14px 20px;
}

span {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  letter-spacing: -0.21px;
  line-height: 20px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
}

.ui.input > input {
  font-family: "Open Sans", sans-serif;
  border: 0.9px solid $boxLightBorder;
}

.react-datepicker {
  z-index: 20;
}

label.jodit-ui-checkbox {
  display: none;
}

.ui.error.message {
  background-color: #fff6f6;
  color: $alertColor; // #9f3a38;
  text-align: left;
}

.ui.error.message .header {
  color: $alertColor;
}

.marginCentered {
  margin: 0 auto !important;
}

.no-pointer-events {
  pointer-events: none !important;
}

.mediumSize {
  font-size: 1.15em !important;
}

.titleClickable {
  cursor: pointer;
}

h4.ui.header .sub.header {
  max-width: 200px;
  word-break: break-word;
}

.floatedRight {
  float: right;
}

.coachBaseContainer {
  max-width: $maxWidthSizeInternalContainer;
  @media (max-width: 1600px) {
    max-width: 90% !important;
    min-width: 90% !important;
  }
  @media (max-width: 1550px) {
    max-width: 87% !important;
    min-width: 87% !important;
  }

  @media (max-width: 1400px) {
    max-width: 85% !important;
    min-width: 85% !important;
  }
}

.menuBaseContainer {
  margin-top: 0 !important;
  padding-top: 0 !important;
  max-width: $maxWidthSizeInternalContainer;
  @media (max-width: 1600px) {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
.subSectionTitle {
  font-size: 20px;
  font-weight: 700;
}

.titleCentered {
  text-align: center !important;
  width: 100%;
}
