// Theme colors
$primaryColor: #3aabef;
$secondaryColor: #eb0084;
$alertColor: #e98686;
$successColor: #b8e986;
$positiveStat: #2ecc71;
$negativeStat: #ff4136;
$blockedTagsAlpha40: rgba(219, 10, 10, 0.3);

// Texts Colors
$labelColor: #989faf;
$titleColor: #474c58;
$titleColorAlpha50: #474c58cc; //474c58cc
$textFieldBackground: #fafafb;
$subtitleColor: #5c5c5c;
$defaultPlaceholder: #979cac;
$descriptionColor: #8d959e;
$lightDescriptionColor: #8d959e;
$descriptionColor: #313131;
$darkLine: #161616;
$blockedTagsColor: #db0a0a;
$flaggedTagsColor: #ffce22;
$companyTagColor: rgb(229, 125, 64);
$newGray: #555555;

// Background colors
$backgroundColor: #f8f8fa;
$lightBackgroundColor: #fefefe;
$tableDarkBackground: #f9fafb;
$landingBackground: #f3f3f9;

// Lines colors
$borderColor: #e9e9e9;
$boxLightBorder: #ededed;
$boxColor: #eff3fa;
$boxBorder: #979797;
$lineColor: #83899b;
$shadowColor: #dedede;
$ackBorder: #ff9900;

// Button colors
$buttonColor: #3daef5;
$buttonDisabledColor: #b3d7ff;
$buttonBorderColor: #8d959e;
$buttonDisabledBorderColor: #dadfe4;
$linkColor: #5c5c5c;
$linksColor: #8e94a5;

$iconColor: #dddfe4;
$navBarColor: #000;
$detailsColor: #767e93;
$statsDetails: #80889c;

// Social Networks
$instagramColor: #dd2a7b;
$facebookColor: #266bc7;
$twitterColor: #000000;
$linkedInColor: #0e76a8;
$tiktokColor: #000000;

// Chat
$chatBackground: #e9f3fd;
$chatBorderBox: #dfdee4;

// ACTIONS
$default: #dfdee4;
$premium: #3daef5;
$urgent: #ff9900;

// Units
$spaceUnit: 5px;

$xsSize: 5px;
$smallSize: 10px;
$mediumSize: 15px;
$bigSize: 20px;
$xbigSize: 30px;
$xxbigSize: 35px;

$hugeSize: 50px;
$maxWidthSizeContainer: 1300px;
$maxWidthSizeInternalContainer: 1250px;
$maxWidthFormSize: 1170px;
$maxWidthCard: 939px;
