/*! * * antd v4.3.1 * * Copyright 2015-present, Alipay, Inc. * All rights reserved. * */

[class*="ant-"] {
  input {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }

  &::-ms-clear {
    display: none;
  }
}

[class^="ant-"] {
  input {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }

  &::-ms-clear {
    display: none;
  }
}

[class*="ant-"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  *,
  ::after,
  ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

[class^="ant-"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  *,
  ::after,
  ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

body,
html {
  width: 100%;
  height: 100%;
}

input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr {
  &[data-original-title],
  &[title] {
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input {
  &[type="number"],
  &[type="password"],
  &[type="text"] {
    -webkit-appearance: none;
  }
}

textarea {
  -webkit-appearance: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}

ol {
  ol,
  ul {
    margin-bottom: 0;
  }
}

ul {
  ol,
  ul {
    margin-bottom: 0;
  }
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;

  &:hover {
    color: #40a9ff;
  }

  &:active {
    color: #096dd9;
    text-decoration: none;
    outline: 0;
  }

  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
  }
}

code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  &[type="date"],
  &[type="datetime-local"],
  &[type="month"],
  &[type="time"] {
    -webkit-appearance: listbox;
  }
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::-moz-selection,
::selection {
  color: #fff;
  background: #1890ff;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &::before {
    display: none;
  }

  .anticon-icon {
    display: block;
  }

  &[tabindex] {
    cursor: pointer;
  }
}

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;

  &::before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
    animation: loadingCircle 1s infinite linear;
  }
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[ant-click-animating-without-extra-node="true"],
[ant-click-animating="true"] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node="true"]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: "";
  pointer-events: none;
}

@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-anchor {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  padding-left: 2px;
}

.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}

.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}

.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:only-child {
    margin-bottom: 0;
  }
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff;
}

.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 0.4;
}

.ant-select-multiple {
  .ant-select-selection-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 4px 0 8px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    cursor: default;
    -webkit-transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item-remove {
    font-style: normal;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;

    > * {
      line-height: 1;
    }

    svg {
      display: inline-block;
    }

    &::before {
      display: none;
    }

    .ant-select-multiple .ant-select-selection-item-remove-icon {
      display: block;
    }

    > .anticon {
      vertical-align: -0.2em;
    }

    &:hover {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .ant-select-selection-search {
    position: relative;
    margin-left: 0.5px;
  }

  .ant-select-selection-search-input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5715;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
    min-width: 3px;
  }

  .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 6.5px;
  }

  .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &.ant-select-lg {
    .ant-select-selection-item {
      height: 32px;
      line-height: 30px;
    }

    .ant-select-selection-search {
      height: 33px;
      line-height: 33px;
    }

    .ant-select-selection-search-input {
      height: 32px;
      line-height: 30px;
    }
  }

  &.ant-select-sm {
    .ant-select-selection-item {
      height: 16px;
      line-height: 14px;
    }

    .ant-select-selection-search {
      height: 17px;
      line-height: 17px;
    }

    .ant-select-selection-search-input {
      height: 16px;
      line-height: 14px;
    }

    .ant-select-selection-placeholder {
      left: 7px;
    }

    .ant-select-selection-search:first-child .ant-select-selection-search-input {
      margin-left: 3px;
    }
  }

  &.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
}

.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}

.ant-select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select-selection-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item {
    -ms-flex: auto;
    flex: auto;

    ::-ms-backdrop {
      -ms-flex: auto;
      flex: auto;
    }
  }
}

.ant-select-selection-placeholder {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.4;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder {
    -ms-flex: auto;
    flex: auto;

    ::-ms-backdrop {
      -ms-flex: auto;
      flex: auto;
    }
  }
}

.ant-select-arrow {
  display: inline-block;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;

  > * {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &::before {
    display: none;
  }

  .ant-select-arrow-icon {
    display: block;
  }

  .anticon {
    vertical-align: top;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    > svg {
      vertical-align: top;
    }

    &:not(.anticon-down) {
      pointer-events: auto;
    }
  }
}

.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;

  &::before {
    display: block;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-select:hover .ant-select-clear {
  opacity: 1;
}

.ant-select-dropdown {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}

.ant-select-item-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ant-select-item-option-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-item-option-state {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #e6f7ff;

  .ant-select-item-option-state {
    color: #1890ff;
  }
}

.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-item-option-grouped {
  padding-left: 24px;
}

.ant-select-lg {
  font-size: 16px;
}

.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;

  img {
    height: 100%;
  }

  svg {
    height: 100%;
    margin: auto;
  }
}

.ant-empty-description {
  margin: 0;
}

.ant-empty-footer {
  margin-top: 16px;
}

.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);

  .ant-empty-image {
    height: 40px;
  }
}

.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);

  .ant-empty-image {
    height: 35px;
  }
}

.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}

.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}

.ant-empty-img-default-path-4,
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}

.ant-empty-img-default-g {
  fill: #fff;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

.ant-avatar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.ant-avatar-image {
  background: 0 0;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;

  > .anticon {
    margin: 0;
  }
}

.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;

  &.ant-avatar-icon {
    font-size: 24px;

    > .anticon {
      margin: 0;
    }
  }
}

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;

  &.ant-avatar-icon {
    font-size: 14px;

    > .anticon {
      margin: 0;
    }
  }
}

.ant-avatar-square {
  border-radius: 2px;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ant-badge {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}

.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.ant-badge-dot {
  z-index: auto;
  width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge .ant-scroll-number-custom-component,
.ant-badge-count,
.ant-badge-dot {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ant-badge-not-a-wrapper {
  .ant-scroll-number {
    position: relative;
    top: auto;
    display: block;
  }

  .ant-badge-count {
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

.ant-scroll-number {
  overflow: hidden;
}

.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }
}

@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }
}

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none;
}

.ant-breadcrumb {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  .anticon {
    font-size: 14px;
  }

  a {
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;

    &:hover {
      color: #40a9ff;
    }
  }

  > span:last-child {
    color: rgba(0, 0, 0, 0.65);

    a {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.ant-breadcrumb-link > .anticon + {
  a,
  span {
    margin-left: 4px;
  }
}

.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

.ant-menu {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: 0;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.ant-menu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-menu-item {
  &:active {
    background: #e6f7ff;
  }

  a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: #1890ff;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
      content: "";
    }
  }

  > .ant-badge a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-item-active,
.ant-menu-item:hover {
  color: #1890ff;
}

.ant-menu-horizontal {
  .ant-menu-item {
    margin-top: -1px;
  }

  > {
    .ant-menu-item-active,
    .ant-menu-item:hover {
      background-color: transparent;
    }
  }
}

.ant-menu-item-selected {
  color: #1890ff;

  a {
    color: #1890ff;

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

  .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    + span {
      opacity: 1;
      -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &.ant-menu-item-only-child > .anticon {
    margin-right: 0;
  }
}

.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  -webkit-box-shadow: none;
  box-shadow: none;

  > {
    .ant-menu-item {
      position: relative;
      top: 1px;
      display: inline-block;
      vertical-align: bottom;
      border-bottom: 2px solid transparent;
    }

    .ant-menu-item-active,
    .ant-menu-item-open,
    .ant-menu-item-selected {
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }

    .ant-menu-item {
      &:hover {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }

      a {
        color: rgba(0, 0, 0, 0.65);

        &:hover {
          color: #1890ff;
        }

        &::before {
          bottom: -2px;
        }
      }
    }

    .ant-menu-item-selected a {
      color: #1890ff;
    }
  }

  &::after {
    display: block;
    clear: both;
    height: 0;
    content: "\20";
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item {
  position: relative;
}

.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-vertical > .ant-menu-item {
  height: 40px;
  line-height: 40px;
}

.ant-menu-inline {
  width: 100%;

  .ant-menu-item-selected::after,
  .ant-menu-selected::after {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-menu-item {
    width: calc(100% + 1px);
  }
}

.ant-menu-inline-collapsed {
  width: 80px;

  > {
    .ant-menu-item,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
      left: 0;
      padding: 0 32px;
      text-overflow: clip;
    }

    .ant-menu-item .anticon,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
      margin: 0;
      font-size: 16px;
      line-height: 40px;
    }

    .ant-menu-item .anticon + span,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }
  }

  .anticon {
    display: inline-block;
  }
}

.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;

  .anticon {
    display: none;
  }

  a {
    color: rgba(255, 255, 255, 0.85);
  }
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;

  .ant-menu-item {
    padding: 0 16px 0 28px;
  }
}

.ant-menu-root {
  &.ant-menu-inline,
  &.ant-menu-vertical,
  &.ant-menu-vertical-left,
  &.ant-menu-vertical-right {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  > .ant-menu-item {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
  }

  .ant-menu-item-group-title {
    padding-left: 32px;
  }
}

.ant-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: 0 0;
  border-color: transparent !important;
  cursor: not-allowed;

  a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none;
  }
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom {
  padding-top: 8px;
}

.ant-tooltip-placement-left {
  padding-right: 8px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: 0 0;
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  pointer-events: auto;
}

.ant-tooltip-placement-top {
  .ant-tooltip-arrow {
    bottom: -5.07106781px;
  }

  .ant-tooltip-arrow-content {
    -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-6.53553391px) rotate(45deg);
    transform: translateY(-6.53553391px) rotate(45deg);
  }

  .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.ant-tooltip-placement-right {
  .ant-tooltip-arrow {
    left: -5.07106781px;
  }

  .ant-tooltip-arrow-content {
    -webkit-box-shadow: (-3px) 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: (-3px) 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(6.53553391px) rotate(45deg);
    transform: translateX(6.53553391px) rotate(45deg);
  }

  .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.ant-tooltip-placement-left {
  .ant-tooltip-arrow {
    right: -5.07106781px;
  }

  .ant-tooltip-arrow-content {
    -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(-6.53553391px) rotate(45deg);
    transform: translateX(-6.53553391px) rotate(45deg);
  }

  .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.ant-tooltip-placement-bottom {
  .ant-tooltip-arrow {
    top: -5.07106781px;
  }

  .ant-tooltip-arrow-content {
    -webkit-box-shadow: (-3px) -3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: (-3px) -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(6.53553391px) rotate(45deg);
    transform: translateY(6.53553391px) rotate(45deg);
  }

  .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.ant-tooltip-pink {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #eb2f96;
  }
}

.ant-tooltip-magenta {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #eb2f96;
  }
}

.ant-tooltip-red {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #f5222d;
  }
}

.ant-tooltip-orange {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #fa8c16;
  }
}

.ant-tooltip-yellow {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #fadb14;
  }
}

.ant-tooltip-green {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #52c41a;
  }
}

.ant-tooltip-blue {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #1890ff;
  }
}

.ant-tooltip-purple {
  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: #722ed1;
  }
}

.ant-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;

  &::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: -7px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: " ";
  }
}

.ant-dropdown-wrap {
  position: relative;

  .ant-btn > .anticon-down {
    display: inline-block;
    font-size: 10px;
  }

  .anticon-down::before {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
}

.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: 0;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-dropdown-menu-item {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  > {
    .anticon:first-child,
    span > .anticon:first-child {
      min-width: 12px;
      margin-right: 8px;
      font-size: 12px;
    }

    a {
      display: block;
      margin: -5px -12px;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.65);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.ant-dropdown-menu-item-selected {
  color: #1890ff;
  background-color: #e6f7ff;

  > a {
    color: #1890ff;
    background-color: #e6f7ff;
  }
}

.ant-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
}

.ant-dropdown-menu-item-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-button > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  vertical-align: baseline;
  display: inline-block;
  font-size: 10px;
}

.ant-dropdown-button {
  white-space: nowrap;

  &.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-color: #d9d9d9;
  outline: 0;

  > .anticon {
    line-height: 1;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:not([disabled]) {
    &:hover {
      text-decoration: none;
    }

    &:active {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &.disabled > *,
  &[disabled] > * {
    pointer-events: none;
  }
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}

.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 2px;
}

.ant-btn {
  > a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: #40a9ff;
    background: #fff;
    border-color: #40a9ff;
  }

  &:focus > a:only-child,
  &:hover > a:only-child {
    color: currentColor;
  }

  &:focus > a:only-child::after,
  &:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }

  &.active,
  &:active {
    color: #096dd9;
    background: #fff;
    border-color: #096dd9;
  }

  &.active > a:only-child,
  &:active > a:only-child {
    color: currentColor;
  }

  &.active > a:only-child::after,
  &:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    background: #fff;
  }

  > span {
    display: inline-block;
  }
}

.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  > a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
  }

  &:focus > a:only-child,
  &:hover > a:only-child {
    color: currentColor;
  }

  &:focus > a:only-child::after,
  &:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }

  &.active,
  &:active {
    color: #fff;
    background: #096dd9;
    border-color: #096dd9;
  }

  &.active > a:only-child,
  &:active > a:only-child {
    color: currentColor;
  }

  &.active > a:only-child::after,
  &:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-primary-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-primary {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-primary-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-primary {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-primary-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-primary {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }
}

.ant-btn-group .ant-btn-primary {
  &:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff;

    &:disabled {
      border-color: #d9d9d9;
    }
  }

  &:first-child:not(:last-child) {
    border-right-color: #40a9ff;

    &[disabled] {
      border-right-color: #d9d9d9;
    }
  }

  + .ant-btn-primary {
    border-left-color: #40a9ff;
  }

  &:last-child:not(:first-child) {
    border-left-color: #40a9ff;

    &[disabled] {
      border-left-color: #d9d9d9;
    }
  }

  + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background: 0 0;
  border-color: #d9d9d9;

  > a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: #40a9ff;
    background: 0 0;
    border-color: #40a9ff;
  }

  &:focus > a:only-child,
  &:hover > a:only-child {
    color: currentColor;
  }

  &:focus > a:only-child::after,
  &:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }

  &.active,
  &:active {
    color: #096dd9;
    background: 0 0;
    border-color: #096dd9;
  }

  &.active > a:only-child,
  &:active > a:only-child {
    color: currentColor;
  }

  &.active > a:only-child::after,
  &:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-ghost-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-ghost {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-ghost-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-ghost {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-ghost-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-ghost {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }
}

.ant-btn-link {
  color: #1890ff;
  background: 0 0;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;

  > a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: #40a9ff;
    background: 0 0;
    border-color: #40a9ff;
  }

  &:focus > a:only-child,
  &:hover > a:only-child {
    color: currentColor;
  }

  &:focus > a:only-child::after,
  &:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }

  &.active,
  &:active {
    color: #096dd9;
    background: 0 0;
    border-color: #096dd9;
  }

  &.active > a:only-child,
  &:active > a:only-child {
    color: currentColor;
  }

  &.active > a:only-child::after,
  &:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-link-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-link {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-link-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-link {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-link-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-link {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:hover {
    background: 0 0;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: transparent;
  }
}

.ant-btn-link-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-link {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: 0 0;
      border-color: transparent;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: 0 0;
      border-color: transparent;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-link-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-link {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-link-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-link {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }
}

.ant-btn-text {
  color: rgba(0, 0, 0, 0.65);
  background: 0 0;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;

  > a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: #40a9ff;
    background: 0 0;
    border-color: #40a9ff;
  }

  &:focus > a:only-child,
  &:hover > a:only-child {
    color: currentColor;
  }

  &:focus > a:only-child::after,
  &:hover > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }

  &.active,
  &:active {
    color: #096dd9;
    background: 0 0;
    border-color: #096dd9;
  }

  &.active > a:only-child,
  &:active > a:only-child {
    color: currentColor;
  }

  &.active > a:only-child::after,
  &:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-text-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-text {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-text-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-text {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-text-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-text {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }

  &:active {
    color: rgba(0, 0, 0, 0.65);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
}

.ant-btn-text-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-text {
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: 0 0;
      border-color: transparent;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: 0 0;
      border-color: transparent;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-btn-text-disabled {
  &.active > a:only-child,
  &:active > a:only-child,
  &:focus > a:only-child,
  &:hover > a:only-child,
  > a:only-child {
    color: currentColor;
  }
}

.ant-btn-text {
  &.disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-text-disabled {
  &.active > a:only-child::after,
  &:active > a:only-child::after,
  &:focus > a:only-child::after,
  &:hover > a:only-child::after,
  > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0;
    content: "";
  }
}

.ant-btn-text {
  &.disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &[disabled] {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -0.5px;

  > * {
    font-size: 16px;
  }

  &.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 2px;

    > * {
      font-size: 18px;
    }
  }

  &.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0 0;
    font-size: 14px;
    border-radius: 2px;

    > * {
      font-size: 14px;
    }
  }
}

.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;

  &.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%;
  }
}

.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}

.ant-btn-circle-outline.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}

.ant-btn {
  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    content: "";
    pointer-events: none;
  }

  .anticon {
    -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.anticon-minus > svg,
    &.anticon-plus > svg {
      shape-rendering: optimizeSpeed;
    }
  }

  &.ant-btn-loading {
    position: relative;

    &:not([disabled]) {
      pointer-events: none;
    }

    &::before {
      display: block;
    }
  }

  > .ant-btn-loading-icon {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .anticon {
      padding-right: 8px;
    }

    &:only-child .anticon {
      padding-right: 0;
    }
  }
}

.ant-btn-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;

  > {
    .ant-btn,
    span > .ant-btn {
      position: relative;
    }

    .ant-btn {
      &.active,
      &:active,
      &:focus,
      &:hover {
        z-index: 2;
      }
    }

    span > .ant-btn {
      &.active,
      &:active,
      &:focus,
      &:hover {
        z-index: 2;
      }
    }

    .ant-btn:disabled,
    span > .ant-btn:disabled {
      z-index: 0;
    }
  }

  .ant-btn-icon-only {
    font-size: 14px;
  }
}

.ant-btn-group-lg {
  > {
    .ant-btn,
    span > .ant-btn {
      height: 40px;
      padding: 6.4px 15px;
      font-size: 16px;
      border-radius: 0;
    }
  }

  .ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0;
  }
}

.ant-btn-group-sm {
  > {
    .ant-btn,
    span > .ant-btn {
      height: 24px;
      padding: 0 7px;
      font-size: 14px;
      border-radius: 0;
    }

    .ant-btn > .anticon,
    span > .ant-btn > .anticon {
      font-size: 14px;
    }
  }

  .ant-btn.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0;
  }
}

.ant-btn + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group {
  .ant-btn + {
    .ant-btn,
    span {
      margin-left: -1px;
    }
  }

  span + .ant-btn {
    margin-left: -1px;
  }

  + {
    .ant-btn,
    .ant-btn-group {
      margin-left: -1px;
    }
  }

  > span + span {
    margin-left: -1px;
  }

  .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }

  .ant-btn {
    border-radius: 0;
  }

  > {
    .ant-btn:first-child,
    span:first-child > .ant-btn {
      margin-left: 0;
    }

    .ant-btn:only-child,
    span:only-child > .ant-btn {
      border-radius: 2px;
    }

    .ant-btn:first-child:not(:last-child),
    span:first-child:not(:last-child) > .ant-btn {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .ant-btn:last-child:not(:first-child),
    span:last-child:not(:first-child) > .ant-btn {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}

.ant-btn-group-sm > {
  .ant-btn:only-child,
  span:only-child > .ant-btn {
    border-radius: 2px;
  }

  .ant-btn:first-child:not(:last-child),
  span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .ant-btn:last-child:not(:first-child),
  span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.ant-btn-group > .ant-btn-group {
  float: left;

  &:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }

  &:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-btn {
  &:active > span,
  &:focus > span {
    position: relative;
  }

  > {
    .anticon + span,
    span + .anticon {
      margin-left: 8px;
    }
  }
}

.ant-btn-background-ghost {
  color: #fff;
  background: 0 0 !important;
  border-color: #fff;

  &.ant-btn-primary {
    color: #1890ff;
    background: 0 0;
    border-color: #1890ff;
    text-shadow: none;

    > a:only-child {
      color: currentColor;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }

    &:focus,
    &:hover {
      color: #40a9ff;
      background: 0 0;
      border-color: #40a9ff;
    }

    &:focus > a:only-child,
    &:hover > a:only-child {
      color: currentColor;
    }

    &:focus > a:only-child::after,
    &:hover > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }

    &.active,
    &:active {
      color: #096dd9;
      background: 0 0;
      border-color: #096dd9;
    }

    &.active > a:only-child,
    &:active > a:only-child {
      color: currentColor;
    }

    &.active > a:only-child::after,
    &:active > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &.ant-btn-primary-disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.ant-btn-primary {
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &.ant-btn-primary-disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &.ant-btn-primary {
    &.disabled {
      &.active > a:only-child,
      &:active > a:only-child,
      &:focus > a:only-child,
      &:hover > a:only-child,
      > a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {
      &.active > a:only-child,
      &:active > a:only-child,
      &:focus > a:only-child,
      &:hover > a:only-child,
      > a:only-child {
        color: currentColor;
      }
    }
  }

  &.ant-btn-primary-disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &.ant-btn-primary {
    &.disabled {
      &.active > a:only-child::after,
      &:active > a:only-child::after,
      &:focus > a:only-child::after,
      &:hover > a:only-child::after,
      > a:only-child::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }

    &[disabled] {
      &.active > a:only-child::after,
      &:active > a:only-child::after,
      &:focus > a:only-child::after,
      &:hover > a:only-child::after,
      > a:only-child::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }
  }

  &.ant-btn-link {
    color: #1890ff;
    background: 0 0;
    border-color: transparent;
    text-shadow: none;
    color: #fff;

    > a:only-child {
      color: currentColor;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }

    &:focus,
    &:hover {
      color: #40a9ff;
      background: 0 0;
      border-color: transparent;
    }

    &:focus > a:only-child,
    &:hover > a:only-child {
      color: currentColor;
    }

    &:focus > a:only-child::after,
    &:hover > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }

    &.active,
    &:active {
      color: #096dd9;
      background: 0 0;
      border-color: transparent;
    }

    &.active > a:only-child,
    &:active > a:only-child {
      color: currentColor;
    }

    &.active > a:only-child::after,
    &:active > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &.ant-btn-link-disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.ant-btn-link {
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &.ant-btn-link-disabled {
    &.active > a:only-child,
    &:active > a:only-child,
    &:focus > a:only-child,
    &:hover > a:only-child,
    > a:only-child {
      color: currentColor;
    }
  }

  &.ant-btn-link {
    &.disabled {
      &.active > a:only-child,
      &:active > a:only-child,
      &:focus > a:only-child,
      &:hover > a:only-child,
      > a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {
      &.active > a:only-child,
      &:active > a:only-child,
      &:focus > a:only-child,
      &:hover > a:only-child,
      > a:only-child {
        color: currentColor;
      }
    }
  }

  &.ant-btn-link-disabled {
    &.active > a:only-child::after,
    &:active > a:only-child::after,
    &:focus > a:only-child::after,
    &:hover > a:only-child::after,
    > a:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: 0 0;
      content: "";
    }
  }

  &.ant-btn-link {
    &.disabled {
      &.active > a:only-child::after,
      &:active > a:only-child::after,
      &:focus > a:only-child::after,
      &:hover > a:only-child::after,
      > a:only-child::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }

    &[disabled] {
      &.active > a:only-child::after,
      &:active > a:only-child::after,
      &:focus > a:only-child::after,
      &:hover > a:only-child::after,
      > a:only-child::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: 0 0;
        content: "";
      }
    }
  }
}

.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

a {
  &.ant-btn {
    padding-top: 0.1px;
    line-height: 30px;
  }

  &.ant-btn-lg {
    line-height: 38px;
  }

  &.ant-btn-sm {
    line-height: 22px;
  }
}

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}

.ant-radio-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;

  .ant-badge-count {
    z-index: 1;
  }

  > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none;
  }
}

.ant-radio-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.ant-radio {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: 0;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: 0;
  cursor: pointer;
}

.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}

.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: "";
}

.ant-radio-wrapper:hover .ant-radio::after,
.ant-radio:hover::after {
  visibility: visible;
}

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
  }
}

.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;

  &::after {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}

.ant-radio-disabled {
  .ant-radio-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    cursor: not-allowed;

    &::after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .ant-radio-input {
    cursor: not-allowed;
  }

  + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;

  a {
    color: rgba(0, 0, 0, 0.65);
  }

  > .ant-radio-button {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0;
  }
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}

.ant-radio-button-wrapper {
  &:not(:first-child)::before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #d9d9d9;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    content: "";
  }

  &:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
  }

  &:last-child {
    border-radius: 0 2px 2px 0;
  }

  &:first-child:last-child {
    border-radius: 2px;
  }

  &:hover {
    position: relative;
    color: #1890ff;
  }

  &:focus-within {
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }

  .ant-radio-inner {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      width: 0;
      height: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;

  &::before {
    background-color: #1890ff;
  }

  &:first-child {
    border-color: #1890ff;
  }

  &:hover {
    color: #40a9ff;
    border-color: #40a9ff;

    &::before {
      background-color: #40a9ff;
    }
  }

  &:active {
    color: #096dd9;
    border-color: #096dd9;

    &::before {
      background-color: #096dd9;
    }
  }

  &:focus-within {
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;

  &:hover {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
  }

  &:active {
    color: #fff;
    background: #096dd9;
    border-color: #096dd9;
  }

  &:focus-within {
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  }
}

.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;

  &:first-child,
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }

  &:first-child {
    border-left-color: #d9d9d9;
  }

  &.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
}

.ant-tag {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  color: rgba(0, 0, 0, 0.65);

  &:hover {
    opacity: 0.85;
  }

  a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }

  .anticon-close {
    display: inline-block;
    font-size: 10px;
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.ant-tag-hidden {
  display: none;
}

.ant-tag-magenta,
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}

.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag > {
  .anticon + span,
  span + .anticon {
    margin-left: 7px;
  }
}

.ant-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: 0 0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-card-head-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-card-head-title {
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
}

.ant-card-body {
  padding: 24px;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset,
    0 1px 0 0 #f0f0f0 inset;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset,
    0 1px 0 0 #f0f0f0 inset;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-card-cover {
  margin-right: -1px;
  margin-left: -1px;

  > * {
    display: block;
    width: 100%;
  }

  img {
    border-radius: 2px 2px 0 0;
  }
}

.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #f0f0f0;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }

  > li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;

    > span {
      position: relative;
      display: block;
      min-width: 32px;
      font-size: 14px;
      line-height: 1.5715;
      cursor: pointer;

      &:hover {
        color: #1890ff;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
      }

      a:not(.ant-btn) {
        display: inline-block;
        width: 100%;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }

      > .anticon {
        display: inline-block;
        width: 100%;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        font-size: 16px;
        line-height: 22px;

        &:hover {
          color: #1890ff;
        }
      }
    }

    &:not(:last-child) {
      border-right: 1px solid #f0f0f0;
    }
  }
}

.ant-card-type-inner {
  .ant-card-head {
    padding: 0 24px;
    background: #fafafa;
  }

  .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 16px 24px;
  }

  .ant-card-extra {
    padding: 13.5px 0;
  }
}

.ant-card-meta {
  margin: -4px 0;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}

.ant-card-meta-detail {
  overflow: hidden;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-card-loading {
  overflow: hidden;

  .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.ant-card-loading-content p {
  margin: 0;
}

.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(207, 216, 220, 0.2)),
    color-stop(rgba(207, 216, 220, 0.4)),
    to(rgba(207, 216, 220, 0.2))
  );
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
  animation: card-loading 1.4s ease infinite;
}

@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.ant-card-small > {
  .ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px;

    > .ant-card-head-wrapper > {
      .ant-card-head-title {
        padding: 8px 0;
      }

      .ant-card-extra {
        padding: 8px 0;
        font-size: 14px;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
  }
}

.ant-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;

  &::after,
  &::before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.ant-row-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ant-row-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ant-row-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.ant-row-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ant-row-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ant-row-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-row-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin: 0 4px;
  vertical-align: -1px;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }

  &:active {
    color: rgba(0, 0, 0, 0.65);
  }

  + i {
    margin-left: 6px;
  }
}

.ant-input-clear-icon-hidden {
  visibility: hidden;
}

.ant-input-clear-icon:last-child {
  margin-right: 0;
}

.ant-input-textarea-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 8px 8px 0 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }

  &:active {
    color: rgba(0, 0, 0, 0.65);
  }

  + i {
    margin-left: 6px;
  }
}

.ant-input-textarea-clear-icon-hidden {
  visibility: hidden;
}

.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::-moz-placeholder {
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #dddfe4;
    background-color: #ffffff;
  }

  &:-moz-placeholder-shown,
  &:-ms-input-placeholder,
  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-input-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-disabled:hover,
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-sm {
  padding: 0 7px;
}

.ant-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }

  > [class*="col-"] {
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;

  > * {
    display: block !important;
  }
}

.ant-input-group {
  .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;

    &:focus,
    &:hover {
      z-index: 1;
      border-right-width: 1px;
    }
  }

  > .ant-input {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.ant-input-group-lg .ant-input {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-group-sm .ant-input {
  padding: 0 7px;
}

.ant-input-group {
  &.ant-input-group-compact {
    display: block;

    &::before {
      display: table;
      content: "";
    }

    &::after {
      display: table;
      clear: both;
      content: "";
    }
  }

  &.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  &.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }

  &.ant-input-group-compact-wrap:not(:first-child):not(:last-child) {
    &:focus,
    &:hover {
      z-index: 1;
    }
  }

  &.ant-input-group-compact {
    > {
      .ant-input:not(:first-child):not(:last-child) {
        &:hover,
        &:focus {
          z-index: 1;
        }
      }

      * {
        display: inline-block;
        float: none;
        vertical-align: top;
        border-radius: 0;
      }

      :not(:last-child) {
        margin-right: -1px;
        border-right-width: 1px;
      }
    }

    .ant-input {
      float: none;
    }

    > {
      .ant-input-group-wrapper .ant-input {
        border-right-width: 1px;
        border-radius: 0;

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-select > .ant-select-arrow {
        z-index: 1;
      }

      :first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      :last-child {
        border-right-width: 1px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
}

.ant-input[type="color"] {
  height: 32px;

  &.ant-input-lg {
    height: 40px;
  }

  &.ant-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.ant-input-search-icon {
  padding: 0 9px;
  margin-left: 0.5em;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::before {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  &::after {
    width: 32px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    border-left: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: "";
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1890ff;
}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}

.ant-checkbox-wrapper:hover .ant-checkbox::after,
.ant-checkbox:hover::after {
  visibility: visible;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
  }
}

.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;

  &::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
}

.ant-checkbox-disabled {
  cursor: not-allowed;

  &.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
  }

  .ant-checkbox-input {
    cursor: not-allowed;
  }

  .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;

    &::after {
      border-color: #f5f5f5;
      border-collapse: separate;
      -webkit-animation-name: none;
      animation-name: none;
    }
  }

  + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover::after {
    visibility: hidden;
  }
}

.ant-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;

  &:hover .ant-checkbox-disabled::after {
    visibility: hidden;
  }

  &.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  + .ant-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}

.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  + .ant-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;

    &::after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: #1890ff;
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: " ";
    }
  }

  &.ant-checkbox-disabled .ant-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.ant-collapse {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;

  > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
      border-radius: 0 0 2px 2px;

      > .ant-collapse-header {
        border-radius: 0 0 2px 2px;
      }
    }

    > .ant-collapse-header {
      position: relative;
      padding: 12px 16px;
      padding-left: 40px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      cursor: pointer;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &::before {
        display: table;
        content: "";
      }

      &::after {
        display: table;
        clear: both;
        content: "";
      }

      .ant-collapse-arrow {
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 50%;
        left: 16px;
        display: inline-block;
        font-size: 12px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        > * {
          line-height: 1;
        }

        svg {
          display: inline-block;
        }

        &::before {
          display: none;
        }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
          display: block;
        }

        svg {
          -webkit-transition: -webkit-transform 0.24s;
          transition: -webkit-transform 0.24s;
          transition: transform 0.24s;
          transition: transform 0.24s, -webkit-transform 0.24s;
        }
      }

      .ant-collapse-extra {
        float: right;
      }

      &:focus {
        outline: 0;
      }
    }

    &.ant-collapse-no-arrow > .ant-collapse-header {
      padding-left: 12px;
    }
  }
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;

  .ant-collapse-arrow {
    right: 16px;
    left: auto;
  }
}

.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;

  > .ant-collapse-content-box {
    padding: 16px;
  }
}

.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}

.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;

  > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
      border-radius: 0;

      .ant-collapse-header {
        border-radius: 0;
      }
    }

    > .ant-collapse-content {
      background-color: transparent;
      border-top: 0;

      > .ant-collapse-content-box {
        padding-top: 4px;
      }
    }
  }
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  > .arrow {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.ant-comment {
  position: relative;
  background-color: inherit;
}

.ant-comment-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0;
}

.ant-comment-avatar {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.ant-comment-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}

.ant-comment-content-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;

  > {
    a,
    span {
      padding-right: 8px;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;

  > {
    *,
    :hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}

.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}

.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;

  > li {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);

    > span {
      padding-right: 10px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      cursor: pointer;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        color: #595959;
      }
    }
  }
}

.ant-divider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  border-top: 1px solid #f0f0f0;
}

.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #f0f0f0;
}

.ant-divider-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;

  &.ant-divider-with-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;

    &::after,
    &::before {
      position: relative;
      top: 50%;
      width: 50%;
      border-top: 1px solid #f0f0f0;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
      content: "";
    }
  }

  &.ant-divider-with-text-left {
    &::before {
      top: 50%;
      width: 5%;
    }

    &::after {
      top: 50%;
      width: 95%;
    }
  }

  &.ant-divider-with-text-right {
    &::before {
      top: 50%;
      width: 95%;
    }

    &::after {
      top: 50%;
      width: 5%;
    }
  }
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-form-item {
  textarea.ant-input {
    height: auto;
  }

  .ant-upload {
    background: 0 0;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      width: 14px;
      height: 14px;
    }
  }

  .ant-checkbox-inline,
  .ant-radio-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
  }

  .ant-checkbox-inline:first-child,
  .ant-radio-inline:first-child {
    margin-left: 0;
  }

  .ant-checkbox-vertical,
  .ant-radio-vertical {
    display: block;
  }

  .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0;
  }

  .ant-input-number + .ant-form-text {
    margin-left: 8px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-input-group .ant-select {
    width: auto;
  }
}

.ant-form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .ant-form-item {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-form-item {
    > {
      .ant-form-item-control {
        display: inline-block;
        vertical-align: top;
      }

      .ant-form-item-label {
        display: inline-block;
        vertical-align: top;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
      }
    }

    .ant-form-text {
      display: inline-block;
    }
  }
}

.ant-form-horizontal {
  .ant-form-item-label {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .ant-form-item-control {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }
}

.ant-form-vertical {
  .ant-form-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;

    > label {
      height: auto;
      margin: 0;

      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;

    > label {
      margin: 0;

      &::after {
        display: none;
      }
    }
  }

  .ant-form .ant-form-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .ant-form-item-control,
    .ant-form-item-label {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.ant-form {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";

  legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }

  label {
    font-size: 14px;
  }

  input {
    &[type="search"] {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    &[type="checkbox"],
    &[type="radio"] {
      line-height: normal;
    }

    &[type="file"] {
      display: block;
    }

    &[type="range"] {
      display: block;
      width: 100%;
    }
  }

  select {
    &[multiple],
    &[size] {
      height: auto;
    }
  }

  input {
    &[type="checkbox"]:focus,
    &[type="file"]:focus,
    &[type="radio"]:focus {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  .ant-form-text {
    display: inline-block;
    padding-right: 8px;
  }
}

.ant-form-small {
  .ant-form-item-label > label {
    height: 24px;
  }

  .ant-form-item-control-input {
    min-height: 24px;
  }
}

.ant-form-large {
  .ant-form-item-label > label {
    height: 40px;
  }

  .ant-form-item-control-input {
    min-height: 40px;
  }
}

.ant-form-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item-label {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label > label {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;

  > .anticon {
    font-size: 14px;
    vertical-align: top;
  }

  &.ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-label > label::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-form-item-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;

  &:first-child:not([class^="ant-col-"]):not([class*=" ant-col-"]) {
    width: 100%;
  }
}

.ant-form-item-control-input {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 32px;
}

.ant-form-item-control-input-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  max-width: 100%;
}

.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }
}

@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }
}

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ant-input-number {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &::-moz-placeholder {
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #bfbfbf;
  }

  &:-moz-placeholder-shown,
  &:-ms-input-placeholder,
  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-input-number-disabled,
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-disabled:hover,
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}

.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-sm {
  padding: 0 7px;
}

.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }

  .ant-input-number-input {
    cursor: not-allowed;
  }
}

.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;

  &::-moz-placeholder {
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #bfbfbf;
  }

  &:-moz-placeholder-shown,
  &:-ms-input-placeholder,
  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}

.ant-input-number-lg {
  padding: 0;
  font-size: 16px;

  input {
    height: 38px;
  }
}

.ant-input-number-sm {
  padding: 0;

  input {
    height: 22px;
    padding: 0 7px;
  }
}

.ant-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.ant-layout-footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ant-layout-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  background: #001529;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}

.ant-layout-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-height: 0;
}

.ant-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;

  * {
    outline: 0;
  }
}

.ant-list-pagination {
  margin-top: 24px;
  text-align: right;

  .ant-pagination-options {
    text-align: left;
  }
}

.ant-list-spin {
  min-height: 40px;
  text-align: center;
}

.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
}

.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}

.ant-list-item-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0;
}

.ant-list-item-meta-avatar {
  margin-right: 16px;
}

.ant-list-item-meta-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;

  > a {
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-action {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;

  > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
    cursor: pointer;

    &:first-child {
      padding-left: 0;
    }
  }
}

.ant-list-header {
  background: 0 0;
}

.ant-list-footer {
  background: 0 0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-list-header {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}

.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}

.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}

.ant-list-vertical {
  .ant-list-item {
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
  }

  .ant-list-item-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .ant-list-item-extra {
    margin-left: 40px;
  }

  .ant-list-item-meta {
    margin-bottom: 16px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }

  .ant-list-item-action {
    margin-top: 16px;
    margin-left: auto;

    > li {
      padding: 0 16px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.ant-list-item-no-flex {
  display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}

.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  .ant-list-footer,
  .ant-list-header,
  .ant-list-item {
    padding-right: 24px;
    padding-left: 24px;
  }

  .ant-list-pagination {
    margin: 16px 24px;
  }

  &.ant-list-sm {
    .ant-list-footer,
    .ant-list-header,
    .ant-list-item {
      padding: 8px 16px;
    }
  }

  &.ant-list-lg {
    .ant-list-footer,
    .ant-list-header,
    .ant-list-item {
      padding: 16px 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-list-item-action,
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}

@media screen and (max-width: 576px) {
  .ant-list-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .ant-list-item-action {
    margin-left: 12px;
  }

  .ant-list-vertical {
    .ant-list-item {
      -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
    }

    .ant-list-item-main {
      min-width: 220px;
    }

    .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
}

.ant-spin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: "";
    pointer-events: none;
  }
}

.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;

  &::after {
    opacity: 0.4;
    pointer-events: auto;
  }
}

.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;

  &:nth-child(1) {
    top: 0;
    left: 0;
  }

  &:nth-child(2) {
    top: 0;
    right: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  &:nth-child(4) {
    bottom: 0;
    left: 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
}

.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}

.ant-spin-sm .ant-spin-dot {
  font-size: 14px;

  i {
    width: 6px;
    height: 6px;
  }
}

.ant-spin-lg .ant-spin-dot {
  font-size: 32px;

  i {
    width: 14px;
    height: 14px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.ant-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";

  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: " ";
  }
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    display: block;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: none;
    transition: none;

    &:hover {
      text-decoration: none;
    }
  }

  &:focus,
  &:hover {
    border-color: #1890ff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:focus a,
  &:hover a {
    color: #1890ff;
  }
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;

  a {
    color: #1890ff;
  }

  &:focus,
  &:hover {
    border-color: #40a9ff;
  }

  &:focus a,
  &:hover a {
    color: #40a9ff;
  }
}

.ant-pagination-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover a {
    border-color: #40a9ff;
  }

  .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:focus .ant-pagination-item-link,
  &:hover .ant-pagination-item-link {
    color: #1890ff;
    border-color: #1890ff;
  }
}

.ant-pagination-disabled {
  cursor: not-allowed;

  &:focus,
  &:hover {
    cursor: not-allowed;
  }

  .ant-pagination-item-link,
  a {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }

  &:focus {
    .ant-pagination-item-link,
    a {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor: not-allowed;
    }
  }

  &:hover {
    .ant-pagination-item-link,
    a {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor: not-allowed;
    }
  }
}

.ant-pagination-slash {
  margin: 0 10px 0 5px;
}

.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {
  .ant-pagination-options {
    vertical-align: top;

    ::-ms-backdrop {
      vertical-align: top;
    }
  }
}

.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;

  .ant-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;

    &::after {
      height: 24px;
      line-height: 24px;
    }
  }
}

.ant-pagination {
  &.mini {
    .ant-pagination-item {
      min-width: 24px;
      height: 24px;
      margin: 0;
      line-height: 22px;

      &:not(.ant-pagination-item-active) {
        background: 0 0;
        border-color: transparent;
      }
    }

    .ant-pagination-next {
      min-width: 24px;
      height: 24px;
      margin: 0;
      line-height: 24px;

      .ant-pagination-item-link {
        background: 0 0;
        border-color: transparent;

        &::after {
          height: 24px;
          line-height: 24px;
        }
      }
    }

    .ant-pagination-options {
      margin-left: 2px;
    }
  }

  &.ant-pagination-disabled {
    cursor: not-allowed;

    .ant-pagination-item {
      background: #f5f5f5;
      border-color: #d9d9d9;
      cursor: not-allowed;

      a {
        color: rgba(0, 0, 0, 0.25);
        background: 0 0;
        border: none;
        cursor: not-allowed;
      }
    }

    .ant-pagination-item-active {
      background: #dbdbdb;
      border-color: transparent;

      a {
        color: #fff;
      }
    }

    .ant-pagination-item-link {
      color: rgba(0, 0, 0, 0.45);
      background: #f5f5f5;
      border-color: #d9d9d9;
      cursor: not-allowed;

      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.45);
        background: #f5f5f5;
        border-color: #d9d9d9;
        cursor: not-allowed;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

.ant-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close {
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: 0 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;

  button + button {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;

  &::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

.ant-page-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}

.ant-page-header-ghost {
  background-color: inherit;
}

.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}

.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}

.ant-page-header-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ant-page-header-heading-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}

.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}

.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;

  > {
    * {
      margin-left: 12px;
      white-space: unset;
    }

    :first-child {
      margin-left: 0;
    }
  }
}

.ant-page-header-content {
  padding-top: 12px;
}

.ant-page-header-footer {
  margin-top: 16px;
}

.ant-page-header-compact .ant-page-header-heading {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ant-progress {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}

.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.ant-progress-steps {
  display: inline-block;
}

.ant-progress-steps-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-progress-steps-item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-progress-steps-item-active {
  background: #1890ff;
}

.ant-progress-small.ant-progress-line {
  font-size: 12px;

  .ant-progress-text .anticon {
    font-size: 12px;
  }
}

.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}

.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
  animation: ant-progress-appear 0.3s;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}

.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-success-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}

.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;

  .anticon {
    font-size: 14px;
  }
}

.ant-progress-circle {
  .ant-progress-inner {
    position: relative;
    line-height: 1;
    background-color: transparent;
  }

  .ant-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1em;
    line-height: 1;
    white-space: normal;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .anticon {
      font-size: 1.16666667em;
    }
  }
}

@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }

  20% {
    width: 0;
    opacity: 0.5;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }

  20% {
    width: 0;
    opacity: 0.5;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;

  > .anticon {
    font-size: 72px;
  }
}

.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.ant-result-extra {
  margin: 32px 0 0 0;
  text-align: center;

  > {
    * {
      margin-right: 8px;
    }

    :last-child {
      margin-right: 0;
    }
  }
}

.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}

@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.ant-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
}

.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;

  .ant-slider-rail {
    width: 4px;
    height: 100%;
  }

  .ant-slider-track {
    width: 4px;
  }

  .ant-slider-handle {
    margin-top: -6px;
    margin-left: -5px;
  }

  .ant-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%;
  }

  .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap;
  }

  .ant-slider-step {
    width: 4px;
    height: 100%;
  }

  .ant-slider-dot {
    top: auto;
    left: 2px;
    margin-bottom: -4px;
  }
}

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
    -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  &:focus {
    border-color: #46a6ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  }

  &.ant-tooltip-open {
    border-color: #1890ff;
  }
}

.ant-slider:hover {
  .ant-slider-rail {
    background-color: #e1e1e1;
  }

  .ant-slider-track {
    background-color: #69c0ff;
  }

  .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }
}

.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}

.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}

.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: 0 0;
}

.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;

  &:first-child,
  &:last-child {
    margin-left: -4px;
  }
}

.ant-slider-dot-active {
  border-color: #8cc8ff;
}

.ant-slider-disabled {
  cursor: not-allowed;

  .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-slider-dot,
  .ant-slider-handle {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }

  .ant-slider-dot,
  .ant-slider-mark-text {
    cursor: not-allowed !important;
  }
}

.ant-space {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ant-space-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ant-space-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-space-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ant-space-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ant-space-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.ant-statistic {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}

.ant-steps {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}

.ant-steps-item {
  position: relative;
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}

.ant-steps-item-container {
  outline: 0;
}

.ant-steps-item:last-child {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;

  > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
}

.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}

.ant-steps-item-icon {
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;

  > .ant-steps-icon {
    position: relative;
    top: -1px;
    color: #1890ff;
    line-height: 1;
  }
}

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;

  &::after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: "";
  }
}

.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 14px;
}

.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps-item-wait {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);

    > .ant-steps-icon {
      color: rgba(0, 0, 0, 0.25);

      .ant-steps-icon-dot {
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }

  > .ant-steps-item-container > .ant-steps-item-content > {
    .ant-steps-item-title {
      color: rgba(0, 0, 0, 0.45);

      &::after {
        background-color: #f0f0f0;
      }
    }

    .ant-steps-item-description {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.ant-steps-item-error {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff4d4f;

    > .ant-steps-icon {
      color: #ff4d4f;

      .ant-steps-icon-dot {
        background: #ff4d4f;
      }
    }
  }

  > .ant-steps-item-container > .ant-steps-item-content > {
    .ant-steps-item-title {
      color: #ff4d4f;

      &::after {
        background-color: #f0f0f0;
      }
    }

    .ant-steps-item-description {
      color: #ff4d4f;
    }
  }
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}

.ant-steps-item-disabled {
  cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
  cursor: pointer;

  .ant-steps-item-description,
  .ant-steps-item-icon .ant-steps-icon,
  .ant-steps-item-subtitle,
  .ant-steps-item-title {
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }

  &:hover {
    .ant-steps-item-description,
    .ant-steps-item-subtitle,
    .ant-steps-item-title {
      color: #1890ff;
    }
  }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) {
  .ant-steps-item {
    margin-right: 16px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;

      .ant-steps-item-title {
        padding-right: 0;
      }
    }
  }

  .ant-steps-item-description {
    max-width: 140px;
    white-space: normal;
  }
}

.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: 0 0;
  border: 0;

  > .ant-steps-icon {
    top: 0;
    left: 0.5px;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
  }
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: 0 0;
}

.ant-steps-small {
  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
  }

  .ant-steps-item-title {
    padding-right: 12px;
    font-size: 14px;
    line-height: 24px;

    &::after {
      top: 12px;
    }
  }

  .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }

  .ant-steps-item-custom .ant-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: 0 0;
    border: 0;
    border-radius: 0;

    > .ant-steps-icon {
      font-size: 24px;
      line-height: 24px;
      -webkit-transform: none;
      transform: none;
    }
  }
}

.ant-steps-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .ant-steps-item {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow: visible;
  }

  .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }

  .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }

  .ant-steps-item-title {
    line-height: 32px;
  }

  .ant-steps-item-description {
    padding-bottom: 12px;
  }

  > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }

  &.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
}

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .ant-steps-item {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      overflow: visible;
    }

    .ant-steps-item-icon {
      float: left;
      margin-right: 16px;
    }

    .ant-steps-item-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }

    .ant-steps-item-title {
      line-height: 32px;
    }

    .ant-steps-item-description {
      padding-bottom: 12px;
    }

    > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      display: none;
    }

    &.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
      line-height: 24px;
    }
  }
}

.ant-steps-label-vertical {
  .ant-steps-item {
    overflow: visible;
  }

  .ant-steps-item-content {
    display: block;
    width: 116px;
    margin-top: 8px;
    text-align: center;
  }

  .ant-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }

  .ant-steps-item-title {
    padding-right: 0;
    padding-left: 0;

    &::after {
      display: none;
    }
  }

  .ant-steps-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.5715;
  }

  &.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 46px;
  }
}

.ant-steps-dot {
  .ant-steps-item-title,
  &.ant-steps-small .ant-steps-item-title {
    line-height: 1.5715;
  }

  .ant-steps-item:first-child .ant-steps-icon-dot,
  &.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px;
  }

  .ant-steps-item-icon,
  &.ant-steps-small .ant-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: 0 0;
    border: 0;
  }

  .ant-steps-item-icon .ant-steps-icon-dot,
  &.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .ant-steps-item-icon .ant-steps-icon-dot::after,
  &.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    background: rgba(0, 0, 0, 0.001);
    content: "";
  }

  .ant-steps-item-content,
  &.ant-steps-small .ant-steps-item-content {
    width: 140px;
  }
}

.ant-steps-vertical.ant-steps-dot {
  .ant-steps-item-icon {
    margin-top: 8px;
    margin-left: 0;
    background: 0 0;
  }

  .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 0;
  }
}

.ant-steps-navigation {
  padding-top: 12px;

  &.ant-steps-small .ant-steps-item-container {
    margin-left: -12px;
  }

  .ant-steps-item {
    overflow: visible;
    text-align: center;
  }

  .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    .ant-steps-item-content {
      max-width: auto;
    }

    .ant-steps-item-title {
      max-width: 100%;
      padding-right: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::after {
        display: none;
      }
    }
  }

  .ant-steps-item {
    &:not(.ant-steps-item-active) .ant-steps-item-container[role="button"] {
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }

    &:last-child {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      &::after {
        display: none;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 100%;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -14px;
      margin-left: -2px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: none;
      border-left: none;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      content: "";
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: inline-block;
      width: 0;
      height: 3px;
      background-color: #1890ff;
      -webkit-transition: width 0.3s, left 0.3s;
      transition: width 0.3s, left 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      content: "";
    }

    &.ant-steps-item-active::before {
      left: 0;
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;

    &::before {
      display: none;
    }

    &.ant-steps-item-active::before {
      top: 0;
      right: 0;
      left: unset;
      display: block;
      width: 3px;
      height: calc(100% - 24px);
    }

    &::after {
      position: relative;
      top: -2px;
      left: 50%;
      display: block;
      width: 8px;
      height: 8px;
      margin-bottom: 8px;
      text-align: center;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}

.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.36s;
  transition: all 0.36s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
}

.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-switch:focus:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-switch-checked {
  background-color: #1890ff;
}

.ant-switch-disabled,
.ant-switch-loading {
  cursor: not-allowed;
  opacity: 0.4;
}

.ant-switch-disabled *,
.ant-switch-loading * {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  -webkit-transition: margin 0.36s;
  transition: margin 0.36s;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: "";
  }
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}

.ant-switch:not(.ant-switch-disabled):active {
  .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }

  &.ant-switch-checked .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }
}

.ant-switch-loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}

.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;

  .ant-switch-inner {
    margin: 0 5px 0 18px;
    font-size: 12px;
  }

  .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  .ant-switch-loading-icon {
    -webkit-transform: translate(-50%, -50%) scale(0.66667);
    transform: translate(-50%, -50%) scale(0.66667);
  }

  &.ant-switch-checked {
    .ant-switch-inner {
      margin: 0 18px 0 5px;
    }

    .ant-switch-handle {
      left: calc(100% - 12px - 2px);
    }
  }
}

.ant-table {
  &.ant-table-middle {
    .ant-table-footer,
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table-title {
      padding: 12px 8px;
    }

    tfoot > tr > {
      td,
      th {
        padding: 12px 8px;
      }
    }

    .ant-table-thead {
      .ant-table-filter-column {
        margin: -12px -8px;
      }

      .ant-table-filter-column-title {
        padding: 12px 2.3em 12px 8px;
      }
    }

    .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin: -12px -8px -12px 25px;
    }
  }

  &.ant-table-small {
    .ant-table-footer,
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table-title {
      padding: 8px 8px;
    }

    tfoot > tr > {
      td,
      th {
        padding: 8px 8px;
      }
    }

    .ant-table-thead {
      .ant-table-filter-column {
        margin: -8px -8px;
      }

      .ant-table-filter-column-title {
        padding: 8px 2.3em 8px 8px;
      }
    }

    .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin: -8px -8px -8px 25px;
    }
  }
}

.ant-table-small {
  .ant-table-thead > tr > th {
    background-color: #fafafa;
  }

  .ant-table-selection-column {
    width: 46px;
    min-width: 46px;
  }
}

.ant-table.ant-table-bordered {
  .ant-table-title {
    border: 1px solid #f0f0f0;
    border-bottom: 0;
  }

  tbody > tr > td {
    border-right: 1px solid #f0f0f0;
  }

  tfoot > tr > {
    td,
    th {
      border-right: 1px solid #f0f0f0;
    }
  }

  thead > tr > th {
    border-right: 1px solid #f0f0f0;
  }

  table thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-container {
    border: 1px solid #f0f0f0;
    border-right: 0;
    border-bottom: 0;
  }

  &.ant-table-scroll-horizontal tr.ant-table-placeholder > td {
    border-right: 0;
  }

  .ant-table-footer {
    border: 1px solid #f0f0f0;
    border-top: 0;
  }
}

.ant-table-wrapper {
  max-width: 100%;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  z-index: 0;
  clear: both;
  background: #fff;
  border-radius: 2px;

  table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
  }

  tfoot > tr > {
    td,
    th {
      position: relative;
      padding: 16px 16px;
      overflow-wrap: break-word;
    }
  }
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-table-title {
  padding: 16px 16px;
}

.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}

.ant-table-thead > tr {
  > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;

    &[colspan]:not([colspan="1"]) {
      text-align: center;
    }
  }

  &:not(:last-child) > th[colspan] {
    border-bottom: 0;
  }
}

.ant-table-tbody > tr {
  > td {
    border-bottom: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }

  &.ant-table-row:hover > td {
    background: #fafafa;
  }

  &.ant-table-row-selected {
    > td {
      background: #e6f7ff;
      border-color: rgba(0, 0, 0, 0.03);
    }

    &:hover > td {
      background: #dcf4ff;
    }
  }

  .ant-table-wrapper:only-child {
    .ant-table {
      margin: -16px -16px -16px 33px;

      td {
        background: 0 0;
      }
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: 0;

      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }
  }
}

.ant-table tfoot > tr > {
  td,
  th {
    border-bottom: 1px solid #f0f0f0;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-table-pagination-left {
  float: left;
}

.ant-table-pagination-center {
  text-align: center;
}

.ant-table-pagination-right {
  float: right;
}

.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

td.ant-table-column-sort {
  background: #fafafa;
}

.ant-table-filter-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -16px -16px;
}

.ant-table-filter-column-title {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}

.ant-table-filter-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-dropdown-menu {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-checkbox-wrapper + span {
    padding-left: 8px;
  }
}

table tr {
  td.ant-table-selection-column,
  th.ant-table-selection-column {
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
  }

  td.ant-table-selection-column .ant-radio-wrapper,
  th.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0;
  }
}

.ant-table-selection {
  position: relative;
}

.ant-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  .anticon {
    display: inline-block;
    font-size: 10px;
    color: #bfbfbf;

    &:hover {
      color: #a6a6a6;
    }
  }
}

.ant-table-row-expand-icon-cell {
  text-align: center;
}

.ant-table-row-indent {
  float: left;
  height: 1px;
}

.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 12px;
  vertical-align: -2px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus,
  &:hover {
    color: #40a9ff;
  }

  &:active {
    color: #096dd9;
    border-color: currentColor;
  }

  &:focus,
  &:hover {
    border-color: currentColor;
  }

  &::after {
    position: absolute;
    background: currentColor;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    content: "";
  }

  &::before {
    position: absolute;
    background: currentColor;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    content: "";
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
  }

  &::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.ant-table-row-expand-icon-collapsed {
  &::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  &::after {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.ant-table-row-expand-icon-spaced {
  background: 0 0;
  border: 0;

  &::after,
  &::before {
    display: none;
    content: none;
  }
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}

.ant-table .ant-table-container {
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    content: "";
    pointer-events: none;
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.ant-table-title {
  border-radius: 2px 2px 0 0;

  + .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    table > thead > tr:first-child th {
      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }
  }
}

.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  table > thead > tr:first-child th {
    &:first-child {
      border-top-left-radius: 2px;
    }

    &:last-child {
      border-top-right-radius: 2px;
    }
  }
}

.ant-table-footer {
  border-radius: 0 0 2px 2px;
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-select-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after,
.ant-select-tree-checkbox:hover::after {
  visibility: visible;
}

.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
  }
}

.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;

  &::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
}

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;

  &.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
  }

  .ant-select-tree-checkbox-input {
    cursor: not-allowed;
  }

  .ant-select-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;

    &::after {
      border-color: #f5f5f5;
      border-collapse: separate;
      -webkit-animation-name: none;
      animation-name: none;
    }
  }

  + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover::after {
    visibility: hidden;
  }
}

.ant-select-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;

  &:hover .ant-select-tree-checkbox-disabled::after {
    visibility: hidden;
  }

  &.ant-select-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  + .ant-select-tree-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-select-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}

.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  + .ant-select-tree-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-select-tree-checkbox-indeterminate {
  .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;

    &::after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: #1890ff;
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: " ";
    }
  }

  &.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.ant-tree-select-dropdown {
  padding: 8px 4px 0;

  .ant-select-tree {
    border-radius: 0;
  }
}

.ant-select-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-select-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-tree {
  .ant-select-tree-checkbox {
    top: initial;
    margin: 4px 8px 0 0;
  }

  .ant-select-tree-node-content-wrapper {
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: 0 0;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &.ant-select-tree-node-selected {
      background-color: #bae7ff;
    }
  }
}

.ant-select-tree-node-content-wrapper[draggable="true"] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-tree-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after,
.ant-tree-checkbox:hover::after {
  visibility: visible;
}

.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &::after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: " ";
  }
}

.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;

  &::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;

  &.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name: none;
  }

  .ant-tree-checkbox-input {
    cursor: not-allowed;
  }

  .ant-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;

    &::after {
      border-color: #f5f5f5;
      border-collapse: separate;
      -webkit-animation-name: none;
      animation-name: none;
    }
  }

  + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover::after {
    visibility: hidden;
  }
}

.ant-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;

  &:hover .ant-tree-checkbox-disabled::after {
    visibility: hidden;
  }

  &.ant-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  + .ant-tree-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-tree-checkbox-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: inline-block;
}

.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  + .ant-tree-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-tree-checkbox-indeterminate {
  .ant-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9;

    &::after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background-color: #1890ff;
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: " ";
    }
  }

  &.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.ant-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-tree-indent {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-tree {
  .ant-tree-checkbox {
    top: initial;
    margin: 4px 8px 0 0;
  }

  .ant-tree-node-content-wrapper {
    min-height: 24px;
    margin: 0;
    padding: 0 4px;
    color: inherit;
    line-height: 24px;
    background: 0 0;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &.ant-tree-node-selected {
      background-color: #bae7ff;
    }
  }
}

.ant-tree-node-content-wrapper[draggable="true"] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-upload {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  outline: 0;
  width: 97%;
  
  p {
    margin: 0;
  }
}

.ant-upload-btn {
  display: block;
  width: 100%;
  outline: 0;
}

.ant-upload {
  input[type="file"] {
    cursor: pointer;
  }

  &.ant-upload-disabled {
    cursor: not-allowed;
  }

  &.ant-upload-select-picture-card {
    display: table;
    float: left;
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;

    > .ant-upload {
      display: table-cell;
      width: 100%;
      height: 100%;
      padding: 8px;
      text-align: center;
      vertical-align: middle;
    }

    &:hover {
      border-color: #1890ff;
    }
  }
}

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}

.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-upload-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  line-height: 1.5715;

  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.ant-upload-list-item-list-type-text:hover {
  .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
  }

  .ant-upload-list-item-name-icon-count-2 {
    padding-right: 28px;
  }
}

.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}

.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}

.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 0;

  &.ant-btn-sm {
    height: 20px;
    line-height: 1;
  }
}

.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}

.ant-upload-list-item-card-actions-btn:focus {
  opacity: 1;
}

.ant-upload-list-item-card-actions {
  &.picture .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }

  .anticon {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;

  > span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .ant-upload-text-icon .anticon,
  .anticon-loading .anticon {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}

.ant-upload-list-item {
  .anticon-close {
    display: inline-block;
    font-size: 10px;
    position: absolute;
    top: 6px;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 0;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &:hover {
    .ant-upload-list-item-info {
      background-color: #f5f5f5;
    }

    .ant-upload-list-item-card-actions-btn,
    .anticon-close {
      opacity: 1;
    }
  }
}

.ant-upload-list-item-error {
  color: #ff4d4f;

  .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-name,
  .ant-upload-text-icon > .anticon {
    color: #ff4d4f;
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
}

.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: 0 0;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: 0 0;
}

.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}

.ant-upload-list-picture .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}

.ant-upload-list-picture-card {
  .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1;
  }

  &.ant-upload-list::after {
    display: none;
  }
}

.ant-upload-list-picture-card-container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}

.ant-upload-list-picture-card {
  .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
  }

  .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;

    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      content: " ";
    }
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    opacity: 1;
  }

  .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    .anticon-delete,
    .anticon-download,
    .anticon-eye {
      z-index: 10;
      width: 16px;
      margin: 0 4px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 16px;
      cursor: pointer;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    .anticon-delete:hover,
    .anticon-download:hover,
    .anticon-eye:hover {
      color: #fff;
    }

    &:hover {
      opacity: 1;
    }
  }

  .ant-upload-list-item-info:hover + .ant-upload-list-item-actions {
    opacity: 1;
  }

  .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5715;
    text-align: center;
  }

  .ant-upload-list-item-file + .ant-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block;
  }

  .ant-upload-list-item-progress {
    bottom: 32px;
    padding-left: 0;
  }
}

.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: 700;
}
